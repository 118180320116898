#introduction {
height: calc(100vh - 4rem);
width: 100vw;
max-width: 75rem;
margin: 0 auto;
overflow: hidden;
position: relative;

}

.bg{
    position: absolute;
    top: -4rem;
    height: 100vh;
    right:0;
    z-index: -1;
    object-fit: cover;
    border-radius: 40%;

}

.introContent{
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.hello{
    font-size: 1.75rem;
    font-weight: 300;
}
.introName{
    color: rgb(227, 196, 15);
}
.introPara{
    font-size: medium;
    font-weight: 100;
   
}

.hireMe {
  appearance: none;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 15px;
  box-sizing: border-box;
  color: gold;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: clamp(16px,7vw,12px);
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 30%;
  will-change: transform;
  margin-top: 50px;
}

.hireMe:disabled {
  pointer-events: none;
}

.hireMe:hover {
  color: black;
  background-color: gold;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.hireMe:active {
  box-shadow: none;
  transform: translateY(0);
}

.hireMe1{
    background-color: azure;
    margin: 1rem 0;
    padding: 1rem 2rem;
    border: none;
    border-radius: 2rem;
    width: 10vw;
    height: 7vh;
}

@media screen and (max-width:840px) {

    #introduction{
     margin: 0;
    }
     .bg {
         right: -20vw;
     }
     .introContent {
         font-size: 10vw;
 
     }
     .hello {
         font-size: 4.5vw;
     }
     
 }