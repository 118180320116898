#certificate {
overflow:hidden;
width: 100vw;
max-width: 65rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
margin: 0 auto;
}

.certTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.certDesc { 
    font-weight: 100;
    font-size: 1.2rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.certBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 90%;
    text-align: left;
}

.certBar {
    display: flex;
    align-items:center;
    justify-content:space-evenly;
    margin: 1rem;
    padding: 1.5rem;
    background: rgb(42, 47, 47);
    border-radius: 8rem;
    position: relative;
}

.certBarImg {
    object-fit: cover;
    height: 10rem;
    width: 10rem;
    margin-right: 2rem;
}

.certBarText {
    font-size: 0.75rem;
    font-weight: 400;
}
@media screen and (max-width:840px) {
    .certBarImg{
        height: 8rem;
        width: 8rem;
        /* position: absolute;  */
        left:-20%;
    }

    .certBars {
        width: 90%;
    }

}