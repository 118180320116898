.navbar{
    background-color: rgb(32, 37, 37);
    height: 5rem;
    margin: 0 auto;
    width: 100vw;
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}
.logo {
    object-fit: cover;
    height: 3rem;
    width: 4rem;

}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
color: gold;
padding-bottom: 0.5rem;
border-bottom: 3px solid goldenrod;
}
.active {

    color: gold;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid goldenrod;
        
}

.desktopMenuButton{
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 1rem;
    

}
.desktopMenuImg{
    object-fit: cover;
    height: 5rem;
    width: 7rem;
    margin: 0.5rem;
}